
import { defineComponent, ref, computed } from 'vue';
import { useProfilePreferencesForm } from '@/composables/useUserForm';
import { FetchStates } from '@/helpers/fetch-states';
import { AuthModule } from '@/store/auth';

export default defineComponent({
  name: 'ProfilePreferencesTab',
  setup() {
    const userId = computed(() => AuthModule.account?.id ?? -1);

    const formRef = ref(null);
    const useForm = useProfilePreferencesForm(formRef, userId);

    const canEdit = computed(() => !useForm.signinProviderNotNull.value);

    return {
      userId,
      ...useForm,
      canEdit,
      formRef,
      FetchStates,
    };
  },
});
