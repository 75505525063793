
import router from '@/router';
import { computed, defineComponent } from 'vue';
import ProfileGeneralTab from '../components/profile/ProfileGeneralTab.vue';
import ProfileSecurityTab from '../components/profile/ProfileSecurityTab.vue';
import ProfilePreferencesTab from '../components/profile/ProfilePreferencesTab.vue';
import { AuthModule } from '@/store/auth';

export default defineComponent({
  name: 'ProfilePage',
  components: {
    ProfileGeneralTab,
    ProfileSecurityTab,
    ProfilePreferencesTab,
  },
  setup() {
    const userId = computed(() => AuthModule.account?.id ?? -1);

    const routeToGeneral = () => {
      router.push({ name: 'ProfilePageGeneral' });
    };
    const routeToSecurity = () => {
      router.push({ name: 'ProfilePageSecurity' });
    };
    const routeToPreferences = () => {
      router.push({ name: 'ProfilePagePreferences' });
    };

    // Getting the current editing tab from the router path
    const editTab = computed(() => {
      const currPath = router.currentRoute.value.path;

      if (currPath.includes('/profile/general')) {
        return 'general';
      } else if (currPath.includes('/profile/security')) {
        return 'security';
      } else if (currPath.includes('/profile/preferences')) {
        return 'preferences';
      } else {
        // (If we don't have a tab or we have an invalid tab, redirect to the general tab)
        routeToGeneral();
        return 'none';
      }
    });

    return {
      editTab,
      routeToGeneral,
      routeToSecurity,
      routeToPreferences,
      account: AuthModule.account,
    };
  },
});
