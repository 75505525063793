import {
  createSingleUser,
  fetchSingleUser,
  fetchUserPrefs,
  setUserPrefs,
  updateSingleUser,
  updateUserPrefs,
} from '@/api/accidentplan-api';
import {
  CreateUserDTO,
  SingleUserDTO,
  UpdateUserDTO,
  UserGroups,
} from '@/api/dto/user';
import { MeasurementSystem, UserPrefs } from '@/api/dto/user-prefs';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import router from '@/router';
import { AuthModule } from '@/store/auth';
import { UserPrefsModule } from '@/store/userprefs';
import { reactive } from '@vue/reactivity';
import { ElMessage } from 'element-plus';
import { computed, ref, Ref, watch } from 'vue';

const generateValidatePass = (formRef: Ref<any>, passwordRequired: boolean) => (
  rule: any,
  value: any,
  callback: any,
) => {
  if (value === '' && passwordRequired) {
    callback(new Error('Please input the password'));
  } else {
    formRef.value.validateField('password_confirm');
    callback();
  }
};

const generateValidatePass2 = (form: any) => (
  rule: any,
  value: any,
  callback: any,
) => {
  if (value === '' && form.password !== '') {
    callback(new Error('Please input the password again'));
  } else if (value !== form.password) {
    callback(new Error("The passwords don't match!"));
  } else {
    callback();
  }
};

export function useCreateUserForm(formRef: Ref<any>, defaultOrgId: number) {
  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);

  const form = reactive({
    first_name: '',
    last_name: '',
    user_id: '',
    email: '',
    phone_number: '',
    password: '',
    password_confirm: '',
    customer_admin: false,
    organizations_id: defaultOrgId,
    groups: [] as UserGroups[],
  });

  const isMe = false;
  const isUserDeleted = false;

  const validatePass = generateValidatePass(formRef, false);
  const validatePass2 = generateValidatePass2(form);

  const rules = {
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' },
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' },
    ],
    user_id: [
      { required: true, message: 'User ID is required', trigger: 'blur' },
    ],
    email: [{ required: true, message: 'Email is required', trigger: 'blur' }],
    phone_number: [
      { required: true, message: 'Phone number is required', trigger: 'blur' },
    ],
    password: [
      { required: true, message: 'Password is required', trigger: 'blur' },
      { validator: validatePass, trigger: 'blur' },
    ],
    password_confirm: [{ validator: validatePass2, trigger: 'blur' }],
  };

  const saveUser = async () => {
    // validate the form
    const isValid = await new Promise<boolean>(res =>
      formRef.value.validate((_isValid: boolean) => res(_isValid)),
    );

    if (!isValid) return;

    saveState.value = FetchStates.FETCHING;

    // update the groups array according to the UI,
    // keeping any groups that are not modifiable by the UI
    const settableGroups = [UserGroups.CUSTOMER_ADMIN];
    const newGroups = form.groups.filter(
      group => !settableGroups.includes(group),
    );

    if (form.customer_admin) newGroups.push(UserGroups.CUSTOMER_ADMIN);

    try {
      const createUserDto: CreateUserDTO = {
        first_name: form.first_name,
        last_name: form.last_name,
        user_id: form.user_id,
        email: form.email,
        phone_number: form.phone_number,
        password: form.password,
        organizations_id: form.organizations_id,
        groups: newGroups,
      };

      const resp = await createSingleUser(createUserDto);

      saveState.value = FetchStates.FETCHED;
      ElMessage.success('User saved successfully.');

      router.replace({ name: 'UsersPage' });
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error creating the user.');
      saveState.value = FetchStates.UNFETCHED;
    }
  };

  return {
    saveState,
    rules,
    form,
    isMe,
    isUserDeleted,
    saveUser,
  };
}

export function useEditUserForm(formRef: Ref<any>, userId: Ref<number>) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);
  const signInProvider = ref('');

  const form = reactive({
    first_name: '',
    last_name: '',
    user_id: '',
    email: '',
    phone_number: '',
    password: '',
    password_confirm: '',
    customer_admin: false,
    organizations_id: 0,
    groups: [] as UserGroups[],
  });

  const isMe = computed(() => userId.value === AuthModule.account?.id);
  const isUserDeleted = computed(() =>
    form.groups.includes(UserGroups.DELETED),
  );

  const validatePass = generateValidatePass(formRef, false);
  const validatePass2 = generateValidatePass2(form);

  const signinProviderNotNull = ref(false);

  const rules = {
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' },
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' },
    ],
    user_id: [
      { required: true, message: 'User ID is required', trigger: 'blur' },
    ],
    email: [{ required: true, message: 'Email is required', trigger: 'blur' }],
    phone_number: [
      { required: true, message: 'Phone number is required', trigger: 'blur' },
    ],
    password: [{ validator: validatePass, trigger: 'blur' }],
    password_confirm: [{ validator: validatePass2, trigger: 'blur' }],
  };

  const setUser = (newUser: SingleUserDTO) => {
    form.first_name = newUser.first_name;
    form.last_name = newUser.last_name;
    form.user_id = newUser.user_id;
    form.email = newUser.email;
    form.phone_number = newUser.phone_number;
    form.organizations_id = newUser.organizations_id;
    form.password = '';
    form.password_confirm = '';
    form.customer_admin = newUser.groups.includes(UserGroups.CUSTOMER_ADMIN);
    form.groups = newUser.groups;
    signinProviderNotNull.value = !!newUser.signin_provider;
    signInProvider.value = newUser.signin_provider;
  };

  const saveUser = async () => {
    // validate the form
    const isValid = await new Promise<boolean>(res =>
      formRef.value.validate((_isValid: boolean) => res(_isValid)),
    );

    if (!isValid) return;

    saveState.value = FetchStates.FETCHING;

    // update the groups array according to the UI,
    // keeping any groups that are not modifiable by the UI
    const settableGroups = [UserGroups.CUSTOMER_ADMIN];
    const newGroups = form.groups.filter(
      group => !settableGroups.includes(group),
    );

    if (form.customer_admin) newGroups.push(UserGroups.CUSTOMER_ADMIN);

    try {
      const updateUserDto: UpdateUserDTO = {
        id: userId.value,
        first_name: form.first_name,
        last_name: form.last_name,
        user_id: form.user_id,
        email: form.email,
        phone_number: form.phone_number,
        password: form.password.length > 0 ? form.password : undefined,
        organizations_id: form.organizations_id,
        groups: newGroups,
      };

      const resp = await updateSingleUser(updateUserDto);

      setUser(resp.user);

      saveState.value = FetchStates.FETCHED;
      ElMessage.success('User saved successfully.');

      if (isMe.value) {
        await AuthModule.reFetchUser();
      }
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error saving the user.');
      saveState.value = FetchStates.UNFETCHED;
    }
  };

  const fetchUser = async () => {
    // prevent errors
    if (!Number.isInteger(userId.value)) {
      router.replace({ name: 'UsersPage' });
      return;
    }

    fetchState.value = FetchStates.FETCHING;

    try {
      const resp = await fetchSingleUser(userId.value);
      const user = resp.user;

      setUser(user);

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error fetching the user.');

      router.replace({ name: 'UsersPage' });
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  fetchUser();
  watch(userId, fetchUser);

  return {
    fetchState,
    saveState,
    rules,
    form,
    isMe,
    isUserDeleted,
    signinProviderNotNull,
    fetchUser,
    saveUser,
    signInProvider
  };
}

export function useProfileGeneralForm(formRef: Ref<any>, userId: Ref<number>) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);

  const signinProviderNotNull = ref(false);

  const form = reactive({
    first_name: '',
    last_name: '',
    user_id: '',
    email: '',
    phone_number: '',
    organizations_id: 0,
  });

  const rules = {
    first_name: [
      { required: true, message: 'First name is required', trigger: 'blur' },
    ],
    last_name: [
      { required: true, message: 'Last name is required', trigger: 'blur' },
    ],
    user_id: [
      { required: true, message: 'User ID is required', trigger: 'blur' },
    ],
    email: [{ required: true, message: 'Email is required', trigger: 'blur' }],
    phone_number: [
      { required: true, message: 'Phone number is required', trigger: 'blur' },
    ],
  };

  const setUser = (newUser: SingleUserDTO) => {
    form.first_name = newUser.first_name;
    form.last_name = newUser.last_name;
    form.user_id = newUser.user_id;
    form.email = newUser.email;
    form.phone_number = newUser.phone_number;
    form.organizations_id = newUser.organizations_id;
    signinProviderNotNull.value = !!newUser.signin_provider;
  };

  const saveUser = async () => {
    // validate the form
    const isValid = await new Promise<boolean>(res =>
      formRef.value.validate((_isValid: boolean) => res(_isValid)),
    );

    if (!isValid) return;

    saveState.value = FetchStates.FETCHING;

    try {
      const updateUserDto: UpdateUserDTO = {
        id: userId.value,
        first_name: form.first_name,
        last_name: form.last_name,
        user_id: form.user_id,
        email: form.email,
        phone_number: form.phone_number,
      };

      const resp = await updateSingleUser(updateUserDto);

      setUser(resp.user);

      saveState.value = FetchStates.FETCHED;
      ElMessage.success('User saved successfully.');

      await AuthModule.reFetchUser();
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error saving the user.');
      saveState.value = FetchStates.UNFETCHED;
    }
  };

  const fetchUser = async () => {
    // prevent errors
    if (!Number.isInteger(userId.value)) {
      router.replace({ name: 'UsersPage' });
      return;
    }

    fetchState.value = FetchStates.FETCHING;

    try {
      const resp = await fetchSingleUser(userId.value);
      const user = resp.user;

      setUser(user);

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error fetching the user.');

      router.replace({ name: 'UsersPage' });
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  fetchUser();
  watch(userId, fetchUser);

  return {
    fetchState,
    saveState,
    rules,
    form,
    signinProviderNotNull,
    // signinProviderNotNull.value = !!newUser.signin_provider;
    fetchUser,
    saveUser,
  };
}

export function useProfileSecurityForm(formRef: Ref<any>, userId: Ref<number>) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);

  const signinProviderNotNull = ref(false);

  const form = reactive({
    old_password: '',
    password: '',
    password_confirm: '',
  });

  const validatePass = generateValidatePass(formRef, false);
  const validatePass2 = generateValidatePass2(form);

  const rules = {
    password: [{ validator: validatePass, trigger: 'blur' }],
    password_confirm: [{ validator: validatePass2, trigger: 'blur' }],
  };

  const clearInfo = () => {
    form.old_password = '';
    form.password = '';
    form.password_confirm = '';
  };

  const saveUser = async () => {
    // validate the form
    const isValid = await new Promise<boolean>(res =>
      formRef.value.validate((_isValid: boolean) => res(_isValid)),
    );

    if (!isValid) return;

    saveState.value = FetchStates.FETCHING;

    // reauthenticating the user
    // (todo)
    // try {
    //   await loginRequest(userId.value.toString(), form.old_password);
    // } catch (error) {
    //   console.error(error);

    //   if (error.message == 'Incorrect username or password') {
    //     error.message = 'Failed to reauthenticate user.';
    //   }

    //   alertError(error, 'Failed to reauthenticate user.');
    //   saveState.value = FetchStates.UNFETCHED;
    //   return;
    // }

    // setting the user's information
    try {
      const updateUserDto: UpdateUserDTO = {
        id: userId.value,
        password: form.password.length > 0 ? form.password : undefined,
      };

      const resp = await updateSingleUser(updateUserDto);

      clearInfo();

      saveState.value = FetchStates.FETCHED;
      ElMessage.success('User saved successfully.');
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error saving the user.');
      saveState.value = FetchStates.UNFETCHED;
    }
  };

  const fetchUser = async () => {
    // prevent errors
    if (!Number.isInteger(userId.value)) {
      router.replace({ name: 'UsersPage' });
      return;
    }

    fetchState.value = FetchStates.FETCHING;

    try {
      const resp = await fetchSingleUser(userId.value);
      const user = resp.user;

      signinProviderNotNull.value = !!user.signin_provider;

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error fetching the user.');

      router.replace({ name: 'UsersPage' });
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  fetchUser();
  watch(userId, fetchUser);

  return {
    saveState,
    rules,
    form,
    signinProviderNotNull,
    fetchUser,
    saveUser,
  };
}

export function useProfilePreferencesForm(
  formRef: Ref<any>,
  userId: Ref<number>,
) {
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const saveState = ref<FetchStates>(FetchStates.UNFETCHED);

  const signinProviderNotNull = ref(false);

  // Although the user preferences can technically be any arbitrary object,
  // the preferences tab currently only supports editing:
  // System of Measurement
  // Timezone Offset
  const form = reactive({
    systemOfMeasurement: MeasurementSystem.METRIC,
    timezone: 0,
  });

  const setUser = (newUser: UserPrefs) => {
    form.systemOfMeasurement =
      newUser.systemOfMeasurement ?? MeasurementSystem.METRIC;
    form.timezone = newUser.timezone ?? 0;
  };

  const saveUser = async () => {
    saveState.value = FetchStates.FETCHING;

    try {
      const newUserPrefs: UserPrefs = {
        systemOfMeasurement: form.systemOfMeasurement,
        timezone: form.timezone,
      };

      const resp = await UserPrefsModule.updateUserPrefs(newUserPrefs);

      if (resp) setUser(resp);

      saveState.value = FetchStates.FETCHED;
      ElMessage.success('User saved successfully.');
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error saving the user.');
      saveState.value = FetchStates.UNFETCHED;
    }
  };

  const fetchUser = async () => {
    // prevent errors
    if (!Number.isInteger(userId.value)) {
      router.replace({ name: 'UsersPage' });
      return;
    }

    fetchState.value = FetchStates.FETCHING;

    try {
      const resp = await fetchSingleUser(userId.value);
      const user = resp.user;

      signinProviderNotNull.value = !!user.signin_provider;

      const prefs = await UserPrefsModule.getUserPrefs();

      if (prefs) setUser(prefs);

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);

      alertError(error, 'There was an error fetching the user.');

      router.replace({ name: 'UsersPage' });
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  fetchUser();
  watch(userId, fetchUser);

  return {
    fetchState,
    saveState,
    signinProviderNotNull,
    form,
    fetchUser,
    saveUser,
  };
}
